import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
      > a {
        text-decoration: underline;
      }
    }
  }
`;

const TopBarLinks = () => {
  const { topBarLinks } = useContext(SiteContentContext);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {topBarLinks}
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;

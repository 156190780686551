import { useQuery } from 'react-apollo';
import siblingProductsQuery from './siblingProductsQuery.gql';

export const useSiblingProducts = ({ dataList, product }) => {
  //QUERY
  const result = useQuery(siblingProductsQuery, {
    variables: { articleNumbers: dataList },
    errorPolicy: 'all',
    skip: dataList?.length === 0,
  });

  if (!dataList) return null;

  if (result.data?.products) return [product, ...result.data.products];
  return null;
};

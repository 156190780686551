import ChannelContext from '@jetshop/core/components/ChannelContext';
import { StructuredData } from '@jetshop/core/components/StructuredData';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import React from 'react';

const StructuredProductData = ({
  product,
  initialVariant,
  extraFields = {}
}) => {
  var _a, _b, _c, _d, _e, _f;
  const {
    selectedChannel: { currency, url }
  } = React.useContext(ChannelContext);
  const { selectedVariant: variant } = useProductVariants(product, {
    initialVariant
  });
  let baseProductData = {
    '@id': '#product',
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.name,
    description:
      (product === null || product === void 0 ? void 0 : product.description) ||
      (product === null || product === void 0
        ? void 0
        : product.shortDescription),
    image: (product.images || []).map(image => image.url),
    mpn:
      (product === null || product === void 0 ? void 0 : product.barcode) ||
      product.articleNumber,
    sku: product.articleNumber,
    productID: product.articleNumber,
    category:
      (_a = product.canonicalCategory) === null || _a === void 0
        ? void 0
        : _a.name,
    offers: {
      '@type': 'Offer',
      priceCurrency: currency.format.code,
      price:
        (_b = product.price) === null || _b === void 0 ? void 0 : _b.incVat,
      url:
        url +
        ((_c = product.primaryRoute) === null || _c === void 0
          ? void 0
          : _c.path)
    }
  };
  if (variant) {
    baseProductData = Object.assign(Object.assign({}, baseProductData), {
      image: (
        variant === null || variant === void 0 ? void 0 : variant.images.length
      )
        ? variant.images.map(image => image.url)
        : baseProductData.image,
      sku:
        (variant === null || variant === void 0
          ? void 0
          : variant.articleNumber) || baseProductData.sku,
      mpn:
        (variant === null || variant === void 0 ? void 0 : variant.barcode) ||
        (variant === null || variant === void 0
          ? void 0
          : variant.articleNumber),
      offers: Object.assign(Object.assign({}, baseProductData.offers), {
        price:
          (_e =
            variant === null || variant === void 0 ? void 0 : variant.price) ===
            null || _e === void 0
            ? void 0
            : _e.incVat
      })
    });
  }
  const withInjectedProductData = Object.assign(
    Object.assign({}, baseProductData),
    extraFields
  );
  return React.createElement(StructuredData, { json: withInjectedProductData });
};
export { StructuredProductData };

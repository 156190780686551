import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import React, { useState } from 'react';
import addToCartMutation from '../../Cart/queries/addToCart.gql';
import CartQuery from '../../Cart/queries/cartQuery.gql';
import ProductToastWrapper from './ProductToast';
import { useNotification } from '@jetshop/core/components/Notifications';

const AddToCartForm = ({ children, product, variant, getMissingOptions }) => {
  const [addToCartTrigger, setAddToCartTrigger] = useState(false);
  const [trigger, dismiss] = useNotification();

  const onAddToCartInit = ({ mutationId, quantity, price }) => {
    trigger(
      <ProductToastWrapper
        selectedVariation={variant}
        product={product}
        quantity={quantity}
        price={price}
      />,
      {
        id: mutationId,
        type: 'add-to-cart',
      }
    );

    //AFTER BUY CLICK SCROLL TO RELATED
    if (typeof document !== 'undefined') {
      const addon = document.getElementById('related-products');
      if (addon) {
        const yOffset = -100;
        const y =
          addon.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };
  const onAddToCartSuccess = (data) => {
    setAddToCartTrigger(true);
    setAddToCartTrigger(false);
  };
  const onAddToCartError = () => {
    return ({ variant, mutationId, quantity, price, error }) => {
      dismiss(mutationId);

      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={quantity}
          price={price}
          error={error}
        />,
        {
          type: 'add-to-cart',
        }
      );
    };
  };
  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      onAddToCartSuccess={onAddToCartSuccess}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
      getMissingOptions={getMissingOptions}
    >
      {() => children(addToCartTrigger)}
    </AddToCartFormik>
  );
};

export default AddToCartForm;

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const TextBoxWrapper = styled('div')`
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          ${theme.below.sm} {
            width: 100%;
          }
          img {
            height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor.value
    ? props.textColor.value
    : theme.colors.font;
  const background = props.background.value
    ? props.background.value
    : 'transparent';
  const maxWidth = props.maxWidth && props.maxWidth.value;
  return (
    <TextBoxWrapper data-index={index}>
      <ContentHtml
        background={background}
        textColor={textColor}
        style={{ maxWidth: maxWidth }}
        className={`content-html-text ${textColor} ${textAlign}`}
        content={text}
      />
    </TextBoxWrapper>
  );
};

export default TextBox;

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const PercentWrapper = styled('div')`
  display: inline-block;
  font-size: 1rem;
  background: ${theme.colors.red};
  color: white;
  padding: 1px 6px;
  border-radius: 2px;
`;

export const PercentPrice = props => {
  if (props.previousPrice && props.previousPrice.incVat > props.price.incVat) {
    const percent = Math.round(
      ((props.previousPrice.incVat - props.price.incVat) /
        props.previousPrice.incVat) *
        100
    );
    return (
      <PercentWrapper className="percent-price">- {percent}%</PercentWrapper>
    );
  }
  return null;
};

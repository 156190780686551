import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';
import PlayIcon from '../../ui/icons/PlayIcon';
import { isHtmlVideo } from './ImageSlider';

const Wrapper = styled('div')`
  display: flex;
  ${theme.below.md} {
    width: 100%;
    margin: 0 auto 0;
    order: 2;
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  cursor: pointer;
  border: 2px solid ${theme.colors.white};
  transitionopacity: 0.2s;
  opacity: 0.7;
  max-width: 150px;
  &.selected {
    opacity: 0.95;
    border-color: ${theme.colors.greys[3]};
  }
  &:hover {
    opacity: 0.85;
  }
  &:focus {
    outline: none;
  }
  margin: 0;
  ${theme.above.lg} {
    &:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }
`;

const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  display: block;
  video,
  .image-wrapper {
    position: absolute;
    width: 180%;
    height: auto;
    top: 50%;
    left: -40%;
    transform: translateY(-50%);
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: ${theme.colors.white};
    font-size: 2rem;
  }
`;

const Video = ({ data }) => {
  const isHtml = isHtmlVideo(data.url);
  return (
    <VideoWrapper>
      {isHtml ? (
        <video>
          <source src={data.url + '#t=0.1'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="image-wrapper">
          <Image
            src={`https://img.youtube.com/vi/${data.url}/0.jpg`}
            sizes={[50]}
            aspect={'1:1'}
          />
        </div>
      )}

      <PlayIcon />
    </VideoWrapper>
  );
};

const Thumbs = ({ activeSliderImage, images, selectSliderImage }) => {
  const changeImage = (curImageNum, newImageNum) => {
    if (curImageNum === newImageNum) return;
    selectSliderImage(newImageNum, true);
  };
  return (
    <Wrapper className="thumbs-wrapper">
      {images.map((image, index) => (
        <ImageWrapper
          key={index}
          className={activeSliderImage === index && 'selected'}
          onClick={(e) => changeImage(activeSliderImage, index)}
        >
          {image.video ? (
            <Video data={image} />
          ) : (
            <Image
              src={image.url}
              alt={image.alt}
              sizes={[250]}
              aspect={theme.productPage.imageAspect}
              crop={false}
              modifiedDate={image.modifiedDate}
            />
          )}
        </ImageWrapper>
      ))}
    </Wrapper>
  );
};

export default Thumbs;

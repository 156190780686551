import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import ImageComponent from './ImageComponent';
import { RowHeader } from './RowHeader';
import { ScrollBox } from '../../ui/ScrollBox';
import { contentTextElements } from '../ContentText';
import { SliderStyles, sliderSettings } from '../../ui/SlickSlider';
import Slider from 'react-slick/lib/slider';

const ImageRowWrapper = styled.div`
  .image-row-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 100%;
    }
  }
  [data-columns='1'] > div,
  .text-over-2-images,
  .slick-slider {
    width: 100%;
  }
  [data-columns='2'] > div {
    ${theme.above.md} {
      width: 50%;
    }
  }
  [data-columns='3']:not(.mosaic) > div {
    ${theme.above.md} {
      width: 33.33333%;
    }
  }
  [data-columns='4'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
  }
  [data-columns='5'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 20%;
    }
  }
  [data-columns='6'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 33.333%;
    }
  }

  //MOSAIC LOOK
  [data-columns='3'].mosaic {
    display: grid;
    grid-template-columns: 35% 65%;
    > div:nth-of-type(1) {
      grid-area: small1;
    }
    > div:nth-of-type(2) {
      grid-area: small2;
    }
    > div:nth-of-type(3) {
      grid-area: big;
    }
    ${theme.below.md} {
      grid-template-columns: 50% 50%;
      grid-template-rows: 1.8fr 1fr;
      grid-template-areas:
        'big big'
        'small1 small2';
      //IMAGE SIZES
      > div:nth-of-type(1),
      > div:nth-of-type(2) {
        [data-flight-image-container] {
          padding-bottom: 100% !important;
        }
        .content-html-text > button {
          display: none;
        }
      }
      > div:nth-of-type(3) {
        [data-flight-image-container] {
          padding-bottom: 90% !important;
        }
      }
    }
    ${theme.above.lg} {
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'small1 big'
        'small2 big';

      //IMAGE SIZES
      > div:nth-of-type(1),
      > div:nth-of-type(2) {
        [data-flight-image-container] {
          padding-bottom: 83.5% !important;
        }
      }
      > div:nth-of-type(3) {
        [data-flight-image-container] {
          padding-bottom: 90% !important;
        }
      }
    }
  }

  //TEXT OVER 2 IMAGES LOOK
  [data-columns='2'].text-over-2-images {
    position: relative;
    .image-component-wrapper:not(:first-child) {
      .content-html-text {
        display: none;
      }
    }
    ${theme.below.sm} {
      .image-component-wrapper:last-child {
        display: none;
      }
    }
    .image-component-inner {
      position: static;
    }
  }

  //BACKGROUND
  &.background {
    header {
      ${contentTextElements} {
        color: ${theme.startPage.row.color.primary};
      }
    }
    padding: 2rem 5px 2rem 5px;
    ${theme.above.lg} {
      padding: 3rem 5px 2rem 5px;
    }
    .row-header {
      margin: 0 0 1rem;
    }
    .image-row-inner {
      display: flex;
      flex-direction: column;
    }
  }

  //MARGINS BETWEEN IMAGES
  &.margins {
    .row-header {
      margin-bottom: 1rem;
    }
    .slider-items {
      padding-bottom: 2%;
    }
    .image-row-inner {
      max-width: ${theme.general.maxWidth};
      margin: 0 auto;
      padding: 0 ${theme.general.pagePadding.sm};
      ${theme.below.md} {
        padding: 0;
      }
      .image-row-children {
        padding-top: 1.8%;
        > div {
          margin-bottom: 1.8%;
        }
        &[data-columns='2'] > div {
          ${theme.above.md} {
            width: 49%;
          }
        }
        &[data-columns='3']:not(.mosaic) > div {
          ${theme.above.md} {
            width: 32%;
          }
        }
        &[data-columns='4'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.lg} {
            width: 24%;
          }
        }
        &[data-columns='5'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.lg} {
            width: 19%;
          }
        }
        &[data-columns='6'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.lg} {
            width: 32%;
          }
        }

        //MOSAIC LOOK
        &[data-columns='3'].mosaic {
          ${theme.below.md} {
            display: grid;
            grid-template-columns: 49% 49%;
          }
          ${theme.above.lg} {
            > div {
              margin-bottom: 0;
            }
            grid-template-columns: 34.75% 64.9%;
            > div:nth-of-type(1) {
              margin-bottom: 1%;
            }
          }
        }
      }
    }
  }

  //SLIDER WIDTH
  .slider-items {
    .image-component-wrapper {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 41%;
      }
      ${theme.only.md} {
        width: 28%;
      }
      ${theme.only.lg} {
        width: 21%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }

  //SLIDER WIDTH
  &.circle {
    .image-component-wrapper > a .image-component-inner {
      &:before {
        border-radius: 50%;
      }
    }
    .slider-items {
      .image-component-wrapper {
        margin-right: 2%;
        ${theme.only.sm} {
          width: 28%;
        }
        ${theme.only.md} {
          width: 21%;
        }
        ${theme.only.lg} {
          width: 15%;
        }
        ${theme.above.xl} {
          width: 15%;
        }
      }
    }
  }
`;

const ImageRowSliderWrapper = styled.div`
  .slick-slider {
    margin: 0;
  }
  .slick-slide {
    padding: 0;
  }
  .slick-next,
  .slick-prev {
    width: 20px;
    height: 25px;
    background: none;
  }
`;

const ImageRowChildren = ({ children, ...props }) =>
  children.map((child, index) => (
    <ImageComponent key={index} index={index} data={child} {...props} />
  ));

const ImageRowSlider = ({ children, ...props }) => {
  return (
    <ImageRowSliderWrapper>
      <SliderStyles>
        <Slider
          {...{
            ...sliderSettings,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            autoplaySpeed: 6000,
            autoplay: true
          }}
        >
          {children.map((child, index) => (
            <ImageComponent
              {...props}
              data={child}
              columns={1}
              key={index}
              index={index}
            />
          ))}
        </Slider>
      </SliderStyles>
    </ImageRowSliderWrapper>
  );
};

const ImageRow = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const aspect = props.imageAspectRatio.value;
  const margins = props.imageMargins.value;
  const background = props.background.value
    ? props.background.value
    : 'transparent';
  const columns = data.children.length;
  const header = props.header.value;
  const look = props.look.value;

  let cssStyles = margins === 'yes' ? 'margins' : '';
  cssStyles += background ? ' background' : '';
  cssStyles += header ? ' has-header' : '';
  cssStyles += aspect === 'circle' ? ' circle' : '';

  const rowProps = {
    children: data.children,
    columns,
    aspect,
    look,
    critical: index === 0
  };

  if (data && data.children)
    return (
      <ImageRowWrapper
        data-columns={columns}
        data-index={index}
        className={`image-row ${cssStyles}`}
        style={{ background: background }}
      >
        <div className="image-row-inner">
          <RowHeader props={props} />
          {look === 'show-only-one' ? (
            <ImageRowSlider {...rowProps} />
          ) : (
            <>
              {columns > 6 && look === 'standard' ? (
                <ScrollBox gradient={false}>
                  <ImageRowChildren {...rowProps} />
                </ScrollBox>
              ) : (
                <div
                  className={`image-row-children ${look}`}
                  data-columns={columns}
                >
                  <ImageRowChildren {...rowProps} />
                </div>
              )}
            </>
          )}
        </div>
      </ImageRowWrapper>
    );
  else return null;
};

export default ImageRow;

import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarUspWrapper = styled('div')`
  &.top-bar-usp-list {
    > ul {
      > li {
        display: flex;
        align-items: center;
        font-size: ${theme.topBar.usp.fontSize};
        font-weight: ${theme.topBar.usp.fontWeight} !important;
        ${theme.only.xs} {
          font-size: 0.85rem;
        }
        svg {
          margin-right: 5px;
        }
        ${theme.below.sm} {
          opacity: 0;
          position: absolute;
          justify-content: center;
          top: 9px;
          width: 100%;
          transition: opacity 1.5s;
        }
      }
    }

    ${theme.below.sm} {
      &.index-1 > ul > li:nth-of-type(1),
      &.index-2 > ul > li:nth-of-type(2),
      &.index-3 > ul > li:nth-of-type(3) {
        opacity: 1;
      }
    }
  }
`;

const TopBarUsp = ({ data }) => {
  const { topBarUsp } = useContext(SiteContentContext);
  const [index, setIndex] = useState(1);
  const outputData = data ? data : topBarUsp;

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      const nextIndex = index === 3 ? 1 : index + 1;
      setIndex(nextIndex);
    }, 4000);
    return () => clearInterval(interval);
  }, [index]);

  if (outputData)
    return (
      <TopBarUspWrapper className={`top-bar-usp-list index-${index}`}>
        {outputData}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;

import Accordion from '@jetshop/ui/Accordion/Accordion';
import { styled } from 'linaria/react';
import React from 'react';
import posed from 'react-pose';
import { theme } from '../../Theming/Theming';
import DropButton from '../../ui/DropButton';
import { TabContent } from './DesktopTabs';

const DropMenu = styled('div')`
  margin: 0 0 1rem 0;
`;
const DropItem = styled('div')`
  border-top: 1px solid ${theme.colors.border};
  padding: 0.5rem 0;
  &:last-child {
    border-bottom: 1px solid ${theme.colors.border};
  }
  .drop-body {
    display: none;
  }
  &.active {
    .drop-header {
      color: ${theme.colors.primary};
    }
    .drop-body {
      display: block;
    }
  }
`;
const DropHeader = styled('h4')`
  padding: 0.5rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${theme.productPage.tabs.header.fontWeight} !important;
  &:hover {
  }
  .drop-text {
  }
  .drop-button-wrapper {
  }
`;
const DropBody = styled('div')`
  padding: 0.5rem 0;
`;

// work around to make children clickable in AccordionContent
const PosedDiv = posed.div({
  opened: {
    height: 'auto'
  },
  closed: {
    height: 0
  }
});

const PosedAccordionItem = styled(PosedDiv)`
  overflow: hidden;
  height: 0;
`;

const AccordionContent = props => (
  <PosedAccordionItem
    pose={props.isOpen ? 'opened' : 'closed'}
    initialPose="closed"
  >
    {props.children}
  </PosedAccordionItem>
);

export const MobileDrops = ({ tabsData }) => {
  return (
    <DropMenu>
      <Accordion initialOpenIndexes={[0]}>
        {({ openIndexes, handleClick }) => {
          return (
            <>
              {tabsData.map((item, index) => (
                <DropItem key={index}>
                  <DropHeader
                    onClick={() => handleClick(index)}
                    id={'drop-header-' + item.id}
                  >
                    <span className="drop-text">{item.header}</span>
                    <DropButton
                      data-isopen={openIndexes.includes(index)}
                      size={22}
                    />
                  </DropHeader>
                  <AccordionContent isOpen={openIndexes.includes(index)}>
                    <DropBody>
                      <TabContent>{item.body}</TabContent>
                    </DropBody>
                  </AccordionContent>
                </DropItem>
              ))}
            </>
          );
        }}
      </Accordion>
    </DropMenu>
  );
};

import React from 'react';
import { useQuery } from 'react-apollo';
import categoryQuery from './CategoryQuery.gql';
import TextContent from '../../ui/TextContent';

export const BrandTab = ({ id }) => {
  const result = useQuery(categoryQuery, {
    variables: { categoryId: id }
  });
  const brandCategory = result.data?.category;
  if (!brandCategory) return null;
  return <TextContent content={brandCategory.content} className="small" />;
};

import React from 'react';
import { styled } from 'linaria/react';
import TextContent from '../../ui/TextContent';

const ProductDescriptionWrapper = styled('div')`
  //INSERT CSS RULES HERE
`;

export const ProductDescription = ({ product, list }) => {
  if (product && product.description)
    return <TextContent content={product.description} className="small" />;
  else if (list && list.length)
    return (
      <ProductDescriptionWrapper className="content-area small">
        {list}
      </ProductDescriptionWrapper>
    );
  else return null;
};

import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import pageContentQuery from './PageContentQuery.gql';
import catContentQuery from './CatContentQuery.gql';

const PageContent = ({ children, pageId, cat = false }) => {
  const query = cat ? catContentQuery : pageContentQuery;
  return (
    <Fragment>
      <Query query={query} variables={{ id: pageId }}>
        {(result) => {
          const { data } = result;
          const page = cat ? data?.category : data?.page;
          return children(page);
        }}
      </Query>
    </Fragment>
  );
};

export default PageContent;

import React, { useContext } from 'react';
import { theme } from '../Theming/Theming';
import { styled } from 'linaria/react';
import SiteContentContext from '../SiteContent/SiteContentContext';

const ProductUspBoxWrapper = styled('div')`
  width: 100%;
  margin-top: 1rem;
  .title {
    font-weight: ${theme.weights.bold};
    margin-bottom: 1rem;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      margin: 5px 0;
      font-size: 0.9rem;
      span {
      }
      img {
        margin-right: 4px;
      }
    }
  }
`;

const ProductUspBox = () => {
  const { productPageUsp } = useContext(SiteContentContext);
  return <ProductUspBoxWrapper>{productPageUsp}</ProductUspBoxWrapper>;
};

export default ProductUspBox;

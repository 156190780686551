import React, { useState } from 'react';
import { styled } from 'linaria/react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const LightBoxWrapper = styled('div')``;

const ImageGallery = ({ images, setActiveImage, activeSliderImage }) => {
  const [photoIndex, setIndex] = useState(activeSliderImage);
  return (
    <LightBoxWrapper>
      <Lightbox
        mainSrc={images[photoIndex].url}
        nextSrc={images[(photoIndex + 1) % images.length].url}
        prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
        onMovePrevRequest={() =>
          setIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() => setIndex((photoIndex + 1) % images.length)}
        onCloseRequest={() => setActiveImage(false)}
      />
    </LightBoxWrapper>
  );
};

export default ImageGallery;

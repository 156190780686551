import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import addonProductQuery from './addonProductQuery.gql';
import { useQuery } from 'react-apollo';
import { theme } from '../../Theming/Theming';
import { addonField } from '../useCustomData';
import { RowProducts } from '../../ui/ProductRow/RowProducts';

const AddonWrapper = styled('div')`
  margin: 1rem 0 1rem 0;
  width: 100%;
  > h3 {
    margin: 1rem 0;
  }
  //SLIDER WIDTH
  .slider-items {
    .product-card {
      ${theme.only.md} {
        width: 29%;
      }
      ${theme.only.lg} {
        width: 20%;
      }
      ${theme.above.xl} {
        width: 15%;
      }
    }
  }
`;
const AddonProductRender = ({ list, addonList, product }) => {
  const result = useQuery(addonProductQuery, {
    variables: { articleNumbers: list },
    errorPolicy: 'all',
  });

  const products = result.data?.products;

  const category = {
    name: 'addon for:' + product.name,
    products: {
      result: products,
    },
  };

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName: 'Addon products', products }));
  }, [products, track]);

  if (!products) return null;

  return (
    <AddonWrapper id="addon-products">
      <h3>{addonList.title}</h3>
      <RowProducts category={category} slider={true} quickBuy={true} />
    </AddonWrapper>
  );
};

const AddonProducts = ({ customData, product }) => {
  const addonList = customData.filter((item) => item.key === addonField)[0];
  const list = (addonList && addonList.listValues) || [];

  if (list?.length > 0) {
    return (
      <AddonProductRender list={list} addonList={addonList} product={product} />
    );
  }
  return null;
};

export default AddonProducts;

import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import { styled } from 'linaria/react';
import React, { useContext, useState } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { theme } from '../../Theming/Theming';
import { imageOverlay } from '../../ui/General';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import { Color } from './Color';
import { getColor } from './helpers';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    > li {
      text-align: center;
      width: auto;
      margin: 0 5px 5px;
      overflow: hidden;
      > * {
        display: block;
      }
      &.image {
        width: 50px;
      }
      .color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        &.no-color-code {
          &:after {
            content: '';
            transform: rotate(45deg);
            position: absolute;
            height: 2px;
            top: calc(50% - 1px);
            width: 100%;
            background: #999;
            left: 0;
          }
        }
      }
    }
  }
  &:not(.grid) {
    margin-top: 1rem;
    > ul {
      > li {
        &.image {
          width: auto;
          min-width: 55px;
          margin: 0 0 5px 0;
          padding: 0 5px;
          width: 16.666667%;
          padding-bottom: 5px;
          border: none;
          > * {
            ${imageOverlay}
            padding: 2px;
            border-bottom: 5px solid ${theme.colors.white};
          }
          &.selected > * {
            border-color: ${theme.colors.primary};
          }
        }
      }
    }
  }
  &.grid {
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      right: 0;
      width: 50px;
      top: 0;
      content: '';
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 100%;
    }
    > ul {
      flex-wrap: nowrap;
      margin: 0 -2px;
      > li {
        margin: 2px;
        &.image {
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
        min-width: 12px;
        .color {
          width: 12px;
          height: 12px;
          overflow: hidden;
          border: 1px solid ${theme.colors.borderLight};
          &.no-color-code {
            &:after {
              height: 1px;
              top: 3.6px;
            }
          }
        }
        &.selected .color {
          border-color: ${theme.colors.borderInput};
        }
        &.hovered .color {
          border-color: ${theme.colors.font};
        }
      }
    }
  }
`;

export const SiblingButtons = ({
  productList,
  mainProduct,
  grid = false,
  setImage = false,
  setProduct = false
}) => {
  const { otherColorsTitle, colorList } = useContext(SiteContentContext);
  const [active, setActive] = useState(false);
  return (
    <ButtonWrapper className={`sibling-button-wrapper ${grid ? 'grid' : ''}`}>
      {!grid && <VariantHeading>{otherColorsTitle}</VariantHeading>}
      <ul>
        {productList.map((product, index) => {
          const color = grid && colorList && getColor(product);
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const colorCode = grid && color && colorList[color];
          const image =
            product.images && product.images.length > 0 && product.images[0];

          return (
            <li
              key={product.id + color}
              className={`${colorCode ? 'hex-color' : 'image'} ${
                currentProduct ? 'selected' : ''
              } ${active === index ? 'hovered' : ''}`}
              onMouseEnter={() => {
                if (setImage && product?.images.length > 0) {
                  setImage(product.images[0]);
                  setActive(index);
                }
              }}
              onClick={() => {}}
            >
              <Tag
                product={product}
                onClick={e => {
                  if (setProduct) {
                    setProduct(product);
                    e.preventDefault();
                  }
                }}
              >
                {color ? (
                  <Color
                    color={colorCode}
                    className={`color ${colorCode ? '' : 'no-color-code'}`}
                  />
                ) : (
                  <>
                    {image && (
                      <Image
                        sizes={grid ? [20] : [100]}
                        aspect="1:1"
                        crop={true}
                        src={image.url}
                        alt={image.alt}
                        modifiedDate={image.modifiedDate}
                      />
                    )}
                  </>
                )}
              </Tag>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};

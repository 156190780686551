import ProductConfigurationContext from '@jetshop/core/components/ProductConfigurationProvider/ProductConfigurationContext';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { theme } from '../Theming/Theming';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { GridPrice as Price } from '../Price';
import { Price as UIPrice } from '@jetshop/ui/Price';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import NotifyWhenBack from './StockStatus/NotifyWhenBack';
import StockStatusIndicator from './StockStatus/StockStatusIndicator';
import { VariantSelector } from './VariantSelector/VariantSelector';
import InputWithLabel from '../Forms/Input';
import AddToCartForm from './AddToCart/AddToCartForm';
import { Form } from 'formik';
import AddToCartWrapper from './AddToCart/AddToCartWrapper';
import { ProductInfoMarginBottom } from './StyledComponents';
import { useProductValidationMessage } from './helpers';
import loadable from '@loadable/component';
import Spinner from '../ui/Spinner';
import ProductUspBox from './ProductUspBox';
import { Favourite } from '../ProductList/Favourite';
import { Campaigns } from './Campaigns';
import { BreadcrumbsWrapper } from '../Layout/BreadcrumbsWrapper';
import WarehouseStock from './StockStatus/WarehouseStock';
import { getFontSize } from '../Theming/helpers';
import UpsellProducts from './UpsellProduct/UpsellProducts';
import SiblingProducts from './SiblingProducts/SiblingProducts';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { PriceHistoryWrapper } from './Price/PriceHistory';

const LoadablePackageProduct = loadable(
  () => import('./PackageProduct/PackageProduct'),
  {
    fallback: <Spinner />
  }
);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${theme.above.md} {
    align-items: flex-start;
  }
`;

export const Header = styled('header')`
  width: 100%;
  ${ProductInfoMarginBottom};
  .breadcrumbs-wrapper {
    padding: 0.5rem 0;
  }
`;

export const ProductHeading = styled('h1')`
  display: flex;
  justify-content: space-between;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  ${getFontSize(theme.typography.header.fontSize[2])}
`;

const FlexRow = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const PriceWrapper = styled('div')`
  display: flex;
  align-items: baseline;
  [data-flight-price] {
    ${UIPrice.Normal} {
      ${getFontSize(theme.productPage.price.normal.fontSize)}
    }
    ${UIPrice.New} {
      ${getFontSize(theme.productPage.price.new.fontSize)}
      margin-bottom: 0 !important;
    }
    ${UIPrice.Old} {
      ${getFontSize(theme.productPage.price.old.fontSize)}
    }
  }
  .price-history-wrapper {
    margin-left: 10px;
  }
`;

const Col = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &:last-child {
    align-items: flex-end;
    flex-grow: 1;
  }
`;

const StockStatusWrapper = styled('div')`
  .stock-wrapper {
    margin-bottom: 0;
  }
  .stock-label {
    font-weight: ${theme.weights.normal};
    text-transform: none;
  }
  .free-freight-stock {
    display: inline-block;
    margin: 0 0 0 0.8rem;
  }
`;

const VariantsWrapper = styled('div')`
  width: 100%;
  > div {
    ${ProductInfoMarginBottom}
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ProductNameWrapper = styled.span`
  .brand {
  }
  .name {
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeight};
    text-transform: none;
  }
`;

export const ProductName = ({ name, brand, brandCategory = false }) => {
  const rest = name.replace(brand, '');
  if (brand && rest)
    return (
      <ProductNameWrapper>
        {brandCategory ? (
          <CategoryLink category={brandCategory}>
            <span className="brand">{brand}</span>
          </CategoryLink>
        ) : (
          <span className="brand">{brand}</span>
        )}
        <span className="name">{rest}</span>
      </ProductNameWrapper>
    );
  return name;
};

const ShortDescriptionWrapper = styled('article')`
  line-height: ${theme.typography.paragraph.lineHeight};
  ${theme.below.md} {
    font-size: 0.9rem;
  }
  p {
    margin: 0 0 0.5rem 0;
  }
`;

export const ShortDescription = ({ product }) => {
  //DESCRIPTION Short
  const shortdescr =
    product.shortDescription &&
    product.shortDescription.split('\n').reduce((merged, current, index) => {
      merged.push(<p key={index}>{current}</p>);
      return merged;
    }, []);
  return <ShortDescriptionWrapper>{shortdescr}</ShortDescriptionWrapper>;
};

const Details = ({
  product,
  variantHandler,
  breadcrumbProps,
  customData,
  brandCategory
}) => {
  const {
    selectedVariant: selectedVariation,
    validation: variantValidation,
    getMissingOptions,
    missingOptions
  } = variantHandler;

  const selected = selectedVariation || product;
  const configurationContext = useContext(ProductConfigurationContext);
  const stockStatus = useStockStatus(selectedVariation || product);
  const price = useDynamicPrice(product, selectedVariation);
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';

  const { hasVariants, hasUpsell, hidePrice } = product;
  const productValidation = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus
  });

  const completeProduct = product.stockStatus?.text;

  return (
    <Wrapper>
      <Header>
        <div style={{ minHeight: '3.3rem', width: '100%' }}>
          <BreadcrumbsWrapper {...breadcrumbProps} />
        </div>
        <ProductHeading data-testid="product-title">
          <ProductName
            name={product.name}
            brand={brandCategory?.name}
            brandCategory={brandCategory}
          />
          <Favourite product={product} variant={selectedVariation} />
        </ProductHeading>
      </Header>
      <SiblingProducts product={product} />
      {product?.isPackage ? (
        <LoadablePackageProduct product={product} />
      ) : (
        <AddToCartForm
          product={product}
          variant={selectedVariation}
          getMissingOptions={getMissingOptions}
        >
          {addToCartTrigger => {
            return (
              <>
                {product.hasVariants && (
                  <VariantsWrapper>
                    <VariantSelector
                      product={product}
                      variantHandler={variantHandler}
                      showValidation={!!productValidation.validationMessage}
                      customData={customData}
                    />
                  </VariantsWrapper>
                )}
                <FlexRow>
                  <Col>
                    <PriceWrapper>
                      <Price
                        {...price}
                        hidePrice={product.hidePrice}
                        css={{
                          opacity:
                            configurationContext && configurationContext.loading
                              ? 0.3
                              : 1
                        }}
                      />
                      {!hidePrice && (
                        <PriceHistoryWrapper
                          articleNumber={product.articleNumber}
                          variant={selectedVariation}
                        />
                      )}
                    </PriceWrapper>
                  </Col>
                  <Col>
                    {completeProduct && (
                      <StockStatusWrapper>
                        <StockStatusIndicator
                          status={stockStatus.status}
                          text={stockStatus.text}
                        />
                      </StockStatusWrapper>
                    )}
                  </Col>
                </FlexRow>
                {(product?.warehouseStock?.length > 0 ||
                  product?.variants?.values[0]?.warehouseStock?.length > 0) && (
                  <WarehouseStock
                    product={product}
                    variantHandler={variantHandler}
                  />
                )}
                {hasUpsell && (
                  <UpsellProducts
                    product={product}
                    addToCartTrigger={addToCartTrigger}
                  />
                )}
                <StyledForm>
                  {product.customerComments &&
                    product.customerComments.map(comment => (
                      <InputWithLabel
                        wrapperClassName="customer-comment-input-wrapper"
                        className="customer-comment-input"
                        data-testid={`${comment.name}-input`}
                        id={`comments['${comment.name}']`}
                        name={`comments['${comment.name}']`}
                        label={comment.name}
                        key={comment.name}
                      />
                    ))}
                  {!outOfStockNotify && completeProduct && (
                    <AddToCartWrapper
                      variantValidation={variantValidation}
                      productValidation={productValidation}
                      product={product}
                    />
                  )}
                </StyledForm>
                {outOfStockNotify && (
                  <NotifyWhenBack articleNumber={selected.articleNumber} />
                )}
              </>
            );
          }}
        </AddToCartForm>
      )}
      <ProductUspBox />
      <Campaigns campaigns={product.campaigns} />
    </Wrapper>
  );
};

export default Details;

import React from 'react';
import { styled } from 'linaria/react';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { theme } from '../Theming/Theming';

const TextContentWrapper = styled('div')`
  .table-wrapper {
    overflow: auto;
    table {
      width: auto;
      min-width: 100%;
      border: 1px solid ${theme.colors.border};
      border-top: none;
      th {
        background: ${theme.colors.secondary};
        padding: 7px 5px;
        text-align: left;
      }
      td {
        padding: 4px 5px;
        border: 1px solid ${theme.colors.border};
      }
      tr:nth-of-type(2n + 1) {
        background: #e7efeb;
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f9f9f9;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #aaa;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #999;
    }
  }
`;

export const domLink = ({ domNode, options }) => {
  if (
    domNode.name === 'a' &&
    domNode.attribs.href &&
    domNode.attribs.href.length > 0
  ) {
    const href = domNode.attribs.href;
    if (
      href.search('http') !== -1 ||
      href.search('mailto:') !== -1 ||
      href.search('tel:') !== -1
    ) {
      <a href={domNode.attribs.href} className={domNode.attribs.class}>
        {domToReact(domNode.children, options)}
      </a>;
    } else {
      return (
        <Link to={domNode.attribs.href} className={domNode.attribs.class}>
          {domToReact(domNode.children, options)}
        </Link>
      );
    }
  }
  return null;
};

export const HtmlParse = ({ content }) => {
  if (!content) return null;
  const options = {
    replace: (domNode) => {
      if (domNode && !domNode.attribs) return;
      if (domNode.name === 'table') {
        const props = attributesToProps(domNode.attribs);
        return (
          <div className="table-wrapper">
            <table {...props}>{domToReact(domNode.children)}</table>
          </div>
        );
      }
      return domLink({ domNode, options });
    },
  };
  return parse(content, options);
};

export const TextContent = ({ content, className = '' }) => {
  return (
    <TextContentWrapper className={`content-area ${className}`}>
      <HtmlParse content={content} />
    </TextContentWrapper>
  );
};

export default TextContent;

import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

const ColorWrapper = styled('div')`
  > div {
    width: 100%;
    height: 100%;
  }
`;

export const Color = ({ className, color }) => {
  if (!color) return <ColorWrapper className={className} />;
  const multipleColor = color.split('/');
  const multi = multipleColor.length > 1;
  const gradientColor = color.split('->');
  const gradient = gradientColor.length > 1;
  const cssColor =
    (multi &&
      `linear-gradient(to right, #${multipleColor[0]} 0%, #${multipleColor[0]} 50%, #${multipleColor[1]} 50%, #${multipleColor[1]} 100%)`) ||
    (gradient &&
      `linear-gradient(to right, #${gradientColor[0]} 0%, #${gradientColor[1]} 100%)`);
  return (
    <ColorWrapper
      className={cx(
        'color-circle',
        className,
        multi && 'multi',
        gradient && 'gradient'
      )}
    >
      {multi || gradient ? (
        <div
          style={{
            backgroundImage: cssColor
          }}
        />
      ) : (
        <div style={{ backgroundColor: '#' + color }} />
      )}
    </ColorWrapper>
  );
};

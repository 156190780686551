import { styled } from 'linaria/react';

export const stockColors = {
  inStock: '#27AE60',
  outOfStock: '#EB5757',
  notifyWhenBack: '#F2C94C',
  green: '#27AE60',
  orange: '#fb7e1b',
  red: '#fb1b1b'
};

const StockOrb = styled('span')`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  transition: all 0.2s linear;
  background-color: ${({ status }) => stockColors[status]};
  margin-right: 0.5rem;
`;

export default StockOrb;

import React from 'react';
import { styled } from 'linaria/react';
import { RowProducts } from '../../ui/ProductRow/RowProducts';

const RelatedWrapper = styled('div')`
  margin: 2rem 0 1rem 0;
  width: 100%;
  > h3 {
    margin: 0 0 1rem 0;
  }
  .related-product-grid {
  }
`;

const RelatedProducts = ({ products, title }) => {
  const category = {
    name: 'Related products',
    products: {
      result: products
    }
  };

  return (
    <RelatedWrapper id="related-products">
      <h3>{title}</h3>
      <RowProducts
        category={category}
        slider={false}
        quickBuy={true}
        className="tight"
      />
    </RelatedWrapper>
  );
};

export default RelatedProducts;

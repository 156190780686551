import React, { useContext } from 'react';
import PageContent from '../../Util/PageContent';
import { ProductDescription } from './ProductDescription';
import { MobileDrops } from './MobileDrops';
import { TabData } from './TabData';
import { styled } from 'linaria/react';
import GetStartPage from '../../StartPage/GetStartPage';
import TextContent from '../../ui/TextContent';
import { config } from '../../../shop.config.custom';
import { BrandTab } from './BrandTab';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import {
  ContentRender,
  convertPropsToObject,
} from '../../ContentRender/ContentRender';
import CategoryQuery from './CategoryQuery.gql';
import { useQuery } from 'react-apollo';
import t from '@jetshop/intl';
import CustomProductSpecifications from './CustomProductSpecifications';

const ProductTabsWrapper = styled('div')``;

const HeaderAmount = styled('span')`
  margin-left: 5px;
  &:after {
    content: ')';
  }
  &:before {
    content: '(';
  }
`;

const ProductTabs = ({
  product,
  customFields,
  customTabs,
  brandCategory,
  customData,
}) => {
  const tabsData = [];

  //FETCH BRAND CATEGORY
  const { data } = useQuery(CategoryQuery, {
    variables: {
      id: brandCategory?.id,
    },
  });
  const sizeGuide = data?.category?.data?.items?.filter(
    (item) => item.type === 'size-guide'
  )[0];

  const { descriptionTitle, specificationTitle } =
    useContext(SiteContentContext);

  //DESCRIPTION
  tabsData.push({
    header: descriptionTitle,
    id: 'descr',
    body: <ProductDescription product={product} />,
  });

  //SPECIFICATIONS
  if (customFields && customFields.length) {
    tabsData.push({
      header: specificationTitle,
      id: 'spec',
      body: (
        <CustomProductSpecifications
          fields={customFields}
          customData={customData}
        />
      ),
    });
  }

  //GENERAL SIZEGUIDE
  if (sizeGuide && sizeGuide.properties && sizeGuide.properties.length > 0) {
    const props = convertPropsToObject(sizeGuide);
    tabsData.push({
      header: t('Size Guide'),
      id: 'brand-sizeguide',
      body: (
        <TextContent className="custom-tab-page" content={props.html.value} />
      ),
    });
  } else {
    tabsData.push({
      header: (
        <PageContent pageId={405} cat={true}>
          {(page) => <>{page?.name}</>}
        </PageContent>
      ),
      id: 'general-sizeguide',
      body: (
        <PageContent pageId={405} cat={true}>
          {(page) => (
            <>
              <ContentRender data={page?.data} />
              <TextContent
                className="custom-tab-page"
                content={page?.content}
              />
            </>
          )}
        </PageContent>
      ),
    });
  }

  //CUSTOM TABS
  if (customTabs && customTabs.length) {
    customTabs.forEach(function (tab, index) {
      //GET PAGE
      if (tab.getPage || tab.getCat) {
        tabsData.push({
          header: (
            <PageContent pageId={tab.pageId} cat={tab.getCat}>
              {(page) => <>{page?.name}</>}
            </PageContent>
          ),
          id: tab.key,
          body: (
            <PageContent pageId={tab.pageId} cat={tab.getCat}>
              {(page) => (
                <>
                  <ContentRender data={page?.data} />
                  <TextContent
                    className="custom-tab-page"
                    content={page?.content}
                  />
                </>
              )}
            </PageContent>
          ),
        });

        //GET STARTPAGE
      } else if (tab.getStartPage) {
        tabsData.push({
          header: tab.title,
          id: tab.key,
          body: <GetStartPage startPageId={tab.pageId} />,
        });
      }

      //SHOW FIELD DATA
      else {
        tabsData.push({
          header:
            tab.listValues && tab.listValues.length ? (
              <span>
                {tab.title}
                <HeaderAmount className="amount">
                  {tab.listValues.length}
                </HeaderAmount>
              </span>
            ) : (
              tab.title
            ),
          id: 'custom' + tab.key,
          body: <TabData data={tab} />,
        });
      }
    });
  }

  //BRAND TABS
  const brandCat =
    product.categories &&
    product.categories.filter(
      (item) => item.parentId === config.routes.brand.id
    )[0];

  if (brandCat) {
    tabsData.push({
      header: brandCat.name,
      id: 'brand',
      body: <BrandTab id={brandCat.id} />,
    });
  }

  return (
    <ProductTabsWrapper id="product-tabs-wrapper">
      <MobileDrops tabsData={tabsData} />
    </ProductTabsWrapper>
  );
};
export default ProductTabs;

import React, { useState } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../../Theming/Theming';
import Popup from '../../ui/Popup';

export const SpecificationsWrapper = styled('table')`
  width: 100%;
  overflow: hidden;
  ul {
  }
  tr th {
    min-width: 150px;
    text-align: left;
    padding-right: 0.5rem;
  }
  > tr {
    background: none;
    border-bottom: 1px solid ${theme.colors.border};
    height: 40px;
  }
`;

export const FieldWrapper = styled('tr')``;

export const FieldTitle = styled('th')``;

export const FieldData = styled('td')``;

const ClickItem = styled('li')`
  text-decoration: underline;
  margin: 4px 0;
  cursor: pointer;
`;

const StringValues = ({ stringValue }) => <div>{stringValue}</div>;
const HtmlValues = ({ htmlValue }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: htmlValue
    }}
  />
);
const BoolValues = ({ boolValue }) => (
  <div>{boolValue ? t('Yes') : t('No')}</div>
);
const ListValues = ({ listValues }) => (
  <ul>
    {listValues.map((listItem, index) => (
      <li key={index}>{listItem}</li>
    ))}
  </ul>
);
const renderFieldData = (field, index) => {
  const type = field.type;
  switch (type) {
    case 'STRING':
      return <StringValues stringValue={field.stringValue} />;
    case 'HTML':
      return <HtmlValues htmlValue={field.htmlValue} />;
    case 'BOOL':
      return <BoolValues boolValue={field.boolValue} />;
    case 'LIST':
      return <ListValues listValues={field.listValues} />;
    default:
      break;
  }
};

const PopupItem = ({ item }) => {
  const [popup, setPopup] = useState(false);
  return (
    <>
      <ClickItem onClick={() => setPopup(!popup)}>{item.title}</ClickItem>
      {popup && (
        <Popup isOpen={true} closePopup={() => setPopup(false)}>
          <p>{item.text}</p>
        </Popup>
      )}
    </>
  );
};

const CustomProductSpecifications = ({ fields, customData }) => {
  const props = customData.filter(item => item.key === 'egenskaper')[0];
  const propsText = customData.filter(
    item => item.key === 'egenskaper-text'
  )[0];

  return fields ? (
    <SpecificationsWrapper className="specifications-wrapper">
      <tbody>
        {fields.map((field, index) => (
          <FieldWrapper
            className="field-wrapper"
            key={index}
            data-type={field.type}
          >
            <FieldTitle className="field-title">{field.title}</FieldTitle>
            <FieldData className="field-data">
              {renderFieldData(field, index)}
            </FieldData>
          </FieldWrapper>
        ))}
        {props && (
          <tr>
            <th>{props.title}</th>
            <td>
              <ul>
                {props.listValues.map((item, index) => (
                  <PopupItem
                    key={index}
                    item={{ text: propsText.listValues[index], title: item }}
                  />
                ))}
              </ul>
            </td>
          </tr>
        )}
      </tbody>
    </SpecificationsWrapper>
  ) : null;
};
export default CustomProductSpecifications;

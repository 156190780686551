import React from 'react';
import { styled } from 'linaria/react';
import { RowProducts } from '../ui/ProductRow/RowProducts';

const RelewareContainer = styled('section')`
  width: 100%;
`;

const RelewareRow = ({ products, title, className = '' }) => {
  if (products.length < 1) {
    return null;
  }
  const category = {
    name: title,
    products: {
      result: products,
    },
  };
  return (
    <RelewareContainer className={`${className} product-row-outer-wrapper`}>
      <RowProducts category={category} slider={true} title={title} />
    </RelewareContainer>
  );
};

const Releware = ({ product }) => {
  const releware = product?.recommendedProducts;
  if (!releware) return null;
  return (
    <>
      {releware.shuffledToplist && releware.shuffledToplist.length > 0 && (
        <RelewareRow
          products={releware.shuffledToplist}
          title={'Popular products'}
        />
      )}
      {releware.viewed && releware.viewed.length > 0 && (
        <RelewareRow
          products={releware.viewed}
          title={'Others also looked at'}
        />
      )}
      {releware.bought && releware.bought.length > 0 && (
        <RelewareRow products={releware.bought} title={'Others also bought'} />
      )}
    </>
  );
};

export default Releware;

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import BlogItem from '../Blog/BlogItem';

const CategoriesWrapper = styled.div`
  margin-top: 2rem;
  padding: 2rem 1rem;
  ${theme.above.lg} {
    padding: 2rem 3rem;
  }
  width: 100%;
  background: ${theme.colors.backgroundLight};
  > h2.title {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  > h2,
  > h3,
  > h4,
  > h5 {
    text-align: center;
  }
  .item-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 -1rem;
  }
  .item-card {
    padding: 1rem 0;
    width: 100%;
    ${theme.above.md} {
      width: 50%;
      padding: 1rem;
    }
    ${theme.above.lg} {
      width: 33.3%;
    }
  }
`;

export const CategoriesContent = ({
  categories,
  blogPath,
  className,
  title = false,
  subTitle = false,
  ...rest
}) => {
  if (!categories || categories.length === 0 || !blogPath) {
    return null;
  }

  //FILTER OUT BLOG CATEGORIES
  const list = categories.filter(
    (cat) => cat.primaryRoute.path.search(blogPath) !== -1
  );

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <CategoriesWrapper className={className} {...rest}>
      {subTitle && <h3 className="sub-title secondary-font">{subTitle}</h3>}
      {title && <h2 className="title">{title}</h2>}
      <div className="item-list">
        {list.map((category, index) => (
          <BlogItem
            category={category}
            key={index}
            index={index}
            className="item-card"
            showReadMore={true}
          />
        ))}
      </div>
    </CategoriesWrapper>
  );
};

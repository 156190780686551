import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import ProductLink from '@jetshop/ui/ProductLink';
import { Details, ListPrice, Name } from '../../../CategoryPage/ProductCard';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';

const SearchProductWrapper = styled('li')`
  padding: 0.5rem 0.3rem;
  border-bottom: 1px solid ${theme.colors.borderLight};
  &:hover {
    background: ${theme.colors.greys[0]};
  }
  .search-product-details {
    ${theme.below.md} {
      text-align: left;
      [data-flight-price] {
        justify-content: flex-start;
      }
    }
  }
`;

const ProductImageWrapper = styled('div')`
  width: 15%;
  .image-holder {
  }
`;

const StyledProductLink = styled(ProductLink)`
  display: flex;
`;

const ProductInfo = styled(Details)`
  width: 80%;
  padding-left: 1rem;
  line-height: 1;
  > div {
  }
`;

const StyledListPrice = styled(ListPrice)`
  > div {
    font-size: 0.9rem !important;
  }
`;

const SearchProduct = ({ product, onClose, term, fullView }) => {
  const hasImages = product.images && product.images.length > 0;
  const size = fullView ? 500 : 100;
  return (
    <SearchProductWrapper className="search-product">
      <StyledProductLink product={product} onClick={onClose}>
        <ProductImageWrapper className="search-product-image">
          {hasImages ? (
            <Image
              sizes={size}
              aspect={theme.productGrid.product.imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
            ></Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </ProductImageWrapper>
        <ProductInfo className="search-product-details">
          <Name>{product.name}</Name>
          <StyledListPrice
            price={product.price}
            recommendedPrice={product.recommendedPrice}
            previousPrice={product.previousPrice}
          />
        </ProductInfo>
      </StyledProductLink>
    </SearchProductWrapper>
  );
};

export default SearchProduct;

import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import { addwishTracker, loadAddwishScript } from '@jetshop/flight-addwish';
import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import ContentPage from './components/ContentPage/ContentPage.loadable';
import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import routeQuery from './components/RouteQuery.gql';
import { theme } from './components/Theme';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'skoman',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: true,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: []
  },
    intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE || false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: true
  },
  openGraph: {
    disableDefaultProductData: true
  },
  trackers: [
    {
      ...addwishTracker(),
      initBrowser() {
        loadAddwishScript('A14D3A3F6336A95E3C7A0104D1C5E586');

        const script = document.createElement('script');

        script.innerHTML = `
                    window.hrq = window.hrq || [];

                    hrq.push(function(sdk) {
                        if (!window?.Cookiebot?.consent?.marketing) {
                            sdk.setTrackingOptOut(true);
                        }
                    });

                    window.addEventListener('CookiebotOnLoad', () => {
                        window.hrq = window.hrq || [];
                        hrq.push(['setTrackingOptOut', !window?.Cookiebot?.consent?.marketing]);
                    }, false);
                `;

        document.body.appendChild(script);
      }
    }
  ],
  serverTrackers: [
    createFacebookTracker({
      pixelID: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyAscR_WznLtQN6SswBsguZnSZTXl0sVH5M',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  },
  useTrackingConsentAPI: true
};

export default config;

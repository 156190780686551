import React from 'react';
import { styled } from 'linaria/react';
import RightIcon from './icons/RightIcon';
import { theme } from '../Theming/Theming';

const DropButtonWrapper = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  &[data-isopen='true'] svg {
    transform: rotate(90deg);
  }
  &.down {
    svg {
      transform: rotate(90deg);
    }
    &[data-isopen='true'] svg {
      transform: rotate(-90deg);
    }
  }
  svg {
    color: ${theme.colors.font};
    vertical-align: text-bottom !important;
    transition: transform 200ms;
    font-size: 1rem;
    &[data-isopen='true'] {
      transform: rotate(180deg);
    }
  }
`;

const DropButton = ({ onClick, className, size, ...rest }) => (
  <DropButtonWrapper
    onClick={onClick}
    className={'drop-button-wrapper ' + className}
    {...rest}
  >
    <RightIcon />
  </DropButtonWrapper>
);

export default DropButton;

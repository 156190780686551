import Badges from '@jetshop/ui/ProductList/Badges';
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Thumbs from './Thumbs';
import { SliderStyles, sliderSettings } from '../../ui/SlickSlider';
import Slider from 'react-slick';
import { BadgeWrapper } from '../../CategoryPage/ProductCard';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theming/Theming';
import ImageGallery from './ImageGallery';

const Wrapper = styled('div')`
  position: relative;
  //overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  .thumbs-wrapper {
    order: 1;
  }
  ${theme.below.sm} {
    .thumbs-wrapper {
      display: none;
    }
  }
`;

const MainSlider = styled('div')`
  position: relative;
  width: 100%;
  order: 1;
  .slick-slider {
    margin: 0 0 !important;
    .slick-slide {
      border: 2px solid ${theme.colors.white};
      padding: 0 0 !important;
    }
  }
`;

const ImageWrapper = styled('div')`
  cursor: pointer;
  display: block !important;
  &:focus {
    outline: none;
  }
`;

const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 100%;
  video,
  iframe {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  iframe {
    height: 100%;
  }
`;

export function isHtmlVideo(url) {
  return url.search('https') !== -1;
}

const Video = ({ data }) => {
  const isHtml = isHtmlVideo(data.url);
  return (
    <VideoWrapper className={isHtml ? 'html-video' : 'youtube-video'}>
      {isHtml ? (
        <video controls>
          <source src={data.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <iframe
          title="youtube-player"
          id="ytplayer"
          type="text/html"
          src={`https://www.youtube.com/embed/${data.url}`}
          width="800"
          height="450"
          frameBorder="0"
        />
      )}
    </VideoWrapper>
  );
};

const ImageSlider = ({
  product,
  className = '',
  selectedVariation,
  customData,
  showThumbs = true,
}) => {
  //BADGES
  const badges = product.badges;

  //CHECK FOR VIDEO DATA
  const videoData =
    customData && customData.filter((item) => item.key === 'product-video')[0];
  const videos = (videoData && videoData.listValues) || [];

  //IMAGES
  const only_images =
    product.images?.length === 1
      ? [product.images[0], product.images[0]]
      : product.images;

  //SLIDER ITEMS
  const images = product
    ? [
        ...only_images,
        ...videos.map((item) => {
          return { video: true, url: item };
        }),
      ]
    : [];

  //SLIDER REF
  let imageSlider;

  //CLICKED IMAGE
  const [activeImage, setActiveImage] = useState(false);

  //ACTIVE SLIDER IMAGE
  const [activeSliderImage, setActiveSliderImage] = useState(0);

  //UPDATE FOR VARIATION IMAGE
  useEffect(() => {
    if (selectedVariation?.images?.length > 0) {
      for (let i = 0; i < images.length; i++) {
        if (
          images[i].url === selectedVariation.images[0].url &&
          activeSliderImage !== i
        )
          selectSliderImage(i, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariation]);

  const goTo = (index) => {
    imageSlider.slickGoTo(index);
  };
  const selectSliderImage = (i, fromThumb) => {
    if (fromThumb) goTo(i);
    setActiveSliderImage(i);
  };

  return (
    <Above breakpoint="md">
      {(matches) => (
        <Wrapper className={className}>
          <MainSlider
            className="main-wrapper main-slider"
            data-thumbs={showThumbs}
          >
            <SliderStyles>
              <Slider
                {...{
                  ...sliderSettings,
                  slidesToShow: matches ? 2 : 1,
                  slidesToScroll: 1,
                  speed: 200,
                  beforeChange: (current, next) => selectSliderImage(next),
                }}
                ref={(slider) => (imageSlider = slider)}
              >
                {images.map((image, index) => (
                  <ImageWrapper
                    key={index}
                    onClick={() => {
                      if (!image.video) {
                        setActiveImage(image);
                        setActiveSliderImage(index);
                      }
                    }}
                  >
                    {image.video ? (
                      <Video data={image} />
                    ) : (
                      <Image
                        critical={index === 0 ? true : false}
                        src={image.url}
                        alt={image.alt}
                        sizes={[1, 1, 1, 700]}
                        aspect={theme.productPage.imageAspect}
                        crop={false}
                        modifiedDate={image.modifiedDate}
                      />
                    )}
                  </ImageWrapper>
                ))}
              </Slider>
            </SliderStyles>
            <BadgeWrapper>
              <Badges badges={badges} />
            </BadgeWrapper>
          </MainSlider>
          <Above breakpoint="sm">
            {(matches) => (
              <Fragment>
                {matches && showThumbs ? (
                  <Thumbs
                    images={images}
                    selectSliderImage={selectSliderImage}
                    activeSliderImage={activeSliderImage}
                  />
                ) : null}
              </Fragment>
            )}
          </Above>
          {activeImage && (
            <ImageGallery
              images={only_images}
              setActiveImage={setActiveImage}
              activeSliderImage={activeSliderImage}
            />
          )}
        </Wrapper>
      )}
    </Above>
  );
};

export default ImageSlider;

import React from 'react';
import startPageQuery from './StartPageQuery.gql';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import { ContentRender } from '../ContentRender/ContentRender';
import StartPageLoadingState from './StartPageLoadingState';

const StartPageWrapper = styled('div')`
  width: 100%;
  padding: 0 0 2rem;
  display: flex;
  flex-direction: column;
`;

const GetStartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId },
  });
  const { loading, data } = result;
  if (loading || !data) return <StartPageLoadingState />;

  if (data && data.startPage)
    return (
      <StartPageWrapper>
        <ContentRender data={data.startPage.data} />
      </StartPageWrapper>
    );
  else return null;
};

export default GetStartPage;

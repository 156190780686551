import React from 'react';
import Image from '@jetshop/ui/Image';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const Logo = ({ secondary = false, size = [300] }) => {
  const { apolloConfig } = useShopConfig();
  const name = 'logo-' + apolloConfig.shopid;
  let src = `/pub_images/original/${name}.png`;
  if (secondary) src = `/pub_images/original/${name}_white.png`;
  return <Image src={src} sizes={size} aspect={'18:2'} crop={false} />;
};

export default Logo;

export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    brand: {
      id: 406,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  addwish: {
    productPage: {
      alternatives: 'k5f7c636d404c734a251bd6e5',
    },
    notFound: {
      retarget: 'k5de520f1b24e672dc436150e',
      top: 'k5de520f1b24e672dc4361501',
    },
    cart: 'k5de520f1b24e672dc4361504',
  },
  settings: {
    storeName: 'Skoman',
    siteContentId: 215,
  },
};

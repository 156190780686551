import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import TextContent from '../../ui/TextContent';

const Wrapper = styled('div')`
  ul {
    li {
      padding: 0.8rem 0 0.5rem;
      border-bottom: 1px solid ${theme.colors.border};
      span {
        margin-right: 0.5rem;
        &:after {
          content: '.';
        }
      }
    }
  }
`;

export const TabData = ({ data }) => {
  if (data.listValues && data.listValues.length > 0) {
    return (
      <Wrapper className="content-area">
        <ul>
          {data.listValues.map((value, index) => {
            const number = index + 1;
            return (
              <li key={index}>
                <span>{number}</span>
                {value}
              </li>
            );
          })}
        </ul>
      </Wrapper>
    );
  } else {
    let content = data.htmlValue;
    if (data.stringValue) content = data.stringValue;
    return (
      <Wrapper>
        <TextContent content={content} />
      </Wrapper>
    );
  }
};

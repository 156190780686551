import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { theme } from '../Theming/Theming';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import ProductDetails from './ProductDetails';
import ProductTabs from './ProductTabs/ProductTabs';
import Releware from './Releware';
import useCustomData from './useCustomData';
import { ContentPadding } from '../Layout/Content/Content';
import MaxWidth from '../Layout/MaxWidth';
import { useVariantFromUrl } from '@jetshop/core/hooks/useProductVariants/useVariantFromUrl';
import ImageSlider from './ImageSlider/ImageSlider';
import SiteContentContext from '../SiteContent/SiteContentContext';
import { useQuery } from 'react-apollo';
import { CategoriesContent } from './CategoriesContent';
import { Categories } from './Categories';
import AddonProducts from './AddonProducts/AddonProducts';
import RelatedProducts from './RelatedProducts/RelatedProducts';
import { config } from '../../shop.config.custom';
import BrandQuery from './BrandQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useAddwish } from '@jetshop/flight-addwish';
import AddwishRow from '../ui/AddwishRow/AddwishRow';
import { OpenGraphProductData } from '@jetshop/core/components/OpenGraph';
import { StructuredProductData } from './StructuredProductData';

const ProductPageContainer = styled(MaxWidth)`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${theme.above.md} {
    padding-top: 1rem;
  }
  ${ContentPadding}
`;

const ProductContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  ${theme.below.lg} {
  }
  .product-row-outer-wrapper {
    margin: 1rem 0 2rem;
  }
`;

export const Section = styled('section')`
  width: 100%;
  ${theme.above.lg} {
    padding-bottom: 2rem;
    &.product-images-wrapper {
      width: 59%;
    }
    &.product-info-wrapper {
      width: 39%;
    }
  }
  ${theme.below.sm} {
    &.product-images-wrapper {
      margin-left: -${theme.general.pagePadding.sm};
      width: 100vw;
      .main-slider {
        width: 100vw;
      }
    }
  }
`;

export const SectionHeading = styled('h2')`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const ProductTabsWrapper = styled('div')`
  margin-top: 1rem;
  width: 100%;
  ${theme.above.md} {
    //width: 50%;
  }
`;

export function getBrand(product) {
  return product?.customFields?.filter(field => field.key === 'marke')[0]
    ?.stringValue;
}

const Product = ({ result: { data, loading }, product, partialProduct }) => {
  //PARTIAL PRODUCT FORM CATEGORY
  if (!product) {
    product = partialProduct;
  }

  //TRANSLATIONS
  const { relatedTitle, bestSellingTitle, blogTitle, blogs } =
    useContext(SiteContentContext);

  const initialVariant = useVariantFromUrl();

  //GET VARIANTS HANDLING
  const variantHandler = useProductVariants(product, {
    initialVariant,
    preselectCheapest: false
  });

  //FETCH BRAND CATEGORY
  const { data: brandData } = useQuery(BrandQuery, {
    variables: {
      id: config.routes.brand.id
    }
  });

  //BRAND
  const brand = getBrand(product);

  //BRAND CAT
  const brandCategory = brandData?.category?.subcategories.filter(
    cat => cat.name === brand
  )[0];

  //ADDWISH
  const { selectedChannel } = useContext(ChannelContext);
  const url =
    product?.primaryRoute && selectedChannel.url + product.primaryRoute.path;
  const hierarchies = data?.route?.breadcrumbs;

  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [config.addwish.productPage.alternatives]: {
        url,
        hierarchies
      }
    },
    pageLoaded: hierarchies
  });

  //CUSTOM DATA TO BE USED ELSEWHERE
  const { customFields, customTabs, customData } = useCustomData({ product });

  //NULL CHECK
  if (!product) {
    return null;
  }

  //VARIANTS VARIABLES
  const { selectedVariant: selectedVariation } = variantHandler;

  //CUSTOM FIX TO SHOW VARIANT IMAGE WHEN JUST COLOR IS SELECTED
  const variantImageSlider =
    selectedVariation ||
    (product?.variants?.options?.length > 1 &&
      variantHandler.missingOptions?.length === 1 &&
      variantHandler.getVariantForSelection(
        variantHandler.missingOptions[0].values[0],
        variantHandler.missingOptions[0]
      ));

  const parents = getParentOrCanonicalRoute(data?.route?.parents, product);
  const breadcrumbProps = {
    breadcrumbs: data?.route?.breadcrumbs,
    parents
  };

  //PATH TO PRIMARY BLOG
  const blogPath =
    blogs && blogs.length > 0 && blogs[0].category?.value?.primaryRoute?.path;

  const hasRelated = product && product.relatedProducts?.length > 0;

  return (
    <>
      <StructuredProductData
        product={product}
        extraFields={{ brand: { type: 'brand', name: brand } }}
      />
      <OpenGraphProductData
        product={product}
        optionalFields={[{ property: 'og:brand', content: brand }]}
      />
      <ProductPageContainer>
        <ProductContainer>
          <Section className="product-images-wrapper">
            <ImageSlider
              product={product}
              customData={customData}
              selectedVariation={variantImageSlider}
            />
          </Section>
          <Section className="product-info-wrapper">
            <ProductDetails
              {...{
                product,
                selectedVariation,
                variantHandler,
                breadcrumbProps,
                brandCategory
              }}
            />
            <ProductTabsWrapper>
              <ProductTabs
                product={product}
                customFields={customFields}
                customTabs={customTabs}
                customData={customData}
                loading={loading}
                brandCategory={brandCategory}
              />
            </ProductTabsWrapper>
            <Categories categories={product.categories} />
          </Section>
          <AddonProducts customData={customData} product={product} />
          {hasRelated && (
            <RelatedProducts
              products={product.relatedProducts}
              title={relatedTitle}
            />
          )}
          <AddwishRow
            data={
              addWishResponseBoxItems[config.addwish.productPage.alternatives]
            }
            id={config.addwish.productPage.alternatives}
            slider={true}
            title={bestSellingTitle}
          />
          <Releware product={product} />
        </ProductContainer>
      </ProductPageContainer>
      <CategoriesContent
        categories={product.categories}
        title={blogTitle}
        blogPath={blogPath}
      />
    </>
  );
};

export default Product;

import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ButtonUI } from '../../ui/ButtonUI';
import { theme } from '../../Theming/Theming';
import { stockStatusMask } from '../StockStatus/StockStatusIndicator';

export const buyHeightStyle = `
  height: 46px;
  ${theme.above.lg}{
    height: 60px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-top: 0.5rem;
  &.disabled {
    button {
      cursor: not-allowed;
    }
  }
`;

export const BuyButton = styled(ButtonUI)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  ${buyHeightStyle};
  width: 100%;
  font-size: 1.1rem;
  text-transform: uppercase;
  background: ${theme.productPage.buyButton.background};
  color: ${theme.productPage.buyButton.color};
  &:hover {
    background: ${theme.productPage.buyButton.background};
    color: ${theme.productPage.buyButton.color};
  }
  ${theme.above.md} {
    max-width: 100%;
    font-size: 1.2rem;
  }
`;

const AddToCartWrapper = ({ productValidation, product }) => {
  const { validationMessage, enableValidation, disabledButton } =
    productValidation;

  const productNotBuyable = !product.stockStatus?.buyable;
  const productStockText = product.stockStatus?.text;

  const { text } = stockStatusMask(validationMessage);

  return (
    <Wrapper className={disabledButton || productNotBuyable ? 'disabled' : ''}>
      <BuyButton
        data-testid="add-to-cart"
        type="submit"
        disabled={disabledButton || productNotBuyable}
        onClick={() => {
          enableValidation();
        }}
        className={`${!!validationMessage ? 'message' : ''}`}
      >
        {productNotBuyable
          ? productStockText
          : !!validationMessage
          ? text
          : t('Add to cart')}
      </BuyButton>
    </Wrapper>
  );
};

export default AddToCartWrapper;

import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import StockOrb from './StockOrb';
import { styled } from 'linaria/react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from '../../ui/Popup';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import t from '@jetshop/intl';

const StockStatusWrapper = styled('p')`
  margin: 0 0 0 0;
  align-items: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  svg {
    margin-left: 6px;
    font-size: 1rem;
  }
`;

const StockStatusIndicator = ({ status, text }) => {
  const [popup, setPopup] = useState(false);
  const { stockStatusInfo } = useContext(SiteContentContext);
  const statusInfo = stockStatusMask(text);

  return (
    <>
      <StockStatusWrapper status={status} onClick={() => setPopup(true)}>
        <StockOrb status={statusInfo.color} />
        <span>{statusInfo.text}</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </StockStatusWrapper>
      <Popup isOpen={popup} closePopup={() => setPopup(false)}>
        {stockStatusInfo}
      </Popup>
    </>
  );
};

export const stockStatusMask = textStatus => {
  const value = parseInt(textStatus);
  let text;
  let color;
  if (value <= 0) {
    text = t('Out of stock');
    color = 'red';
  } else if (value >= 2) {
    text = t('In stock');
    color = 'green';
  } else if (value < 2) {
    text = t('A few left');
    color = 'orange';
  } else {
    if (textStatus === 'I lager') {
      text = textStatus;
      color = 'green';
    } else {
      text = textStatus;
      color = '';
    }
  }

  return { text, color };
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;

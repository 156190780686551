import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router-dom';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { SiblingButtons } from './SiblingButtons';
import { useSiblingProducts } from './useSiblingProducts';
import { getData } from './helpers';

const Wrapper = styled('div')`
  width: 100%;
  &:not(.grid) {
    ${ProductInfoMarginBottom};
  }
`;

const SiblingProductsRender = ({
  product,
  grid,
  setImage,
  setProduct,
  dataList,
}) => {
  const productList = useSiblingProducts({ dataList, product });
  if (!productList) return null;
  return (
    <Wrapper className={`${grid ? 'grid' : ''} sibling-products`}>
      <SiblingButtons
        productList={productList}
        mainProduct={product}
        grid={grid}
        setImage={setImage}
        setProduct={setProduct}
      />
    </Wrapper>
  );
};

const SiblingProducts = ({ product, grid, setImage, setProduct }) => {
  //USING ARTICLENUMBERLIST FROM CUSTOM DATA
  const dataList = getData(product.customFields);

  if (!dataList || dataList.length === 0) return null;

  return (
    <SiblingProductsRender
      {...{ product, grid, setImage, setProduct, dataList }}
    />
  );
};

export default withRouter(SiblingProducts);

import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';

const TabsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
`;

const TabsHeader = styled('div')`
  display: flex;
  border-bottom: 1px solid ${theme.colors.border};
  width: 100%;
  justify-content: space-between;
  &.below-3 {
    justify-content: center;
  }
`;
const HeaderItem = styled('h3')`
  padding: 1rem;
  margin-right: 3rem !important;
  text-align: center;
  cursor: pointer;
  font-weight: ${theme.productPage.tabs.header.fontWeight} !important;
  border-bottom: 5px solid white;
  &:last-child {
    margin-right: 0 !important;
  }
  ${theme.above.lg} {
    width: auto;
  }
  &:hover {
    color: ${theme.colors.font};
  }
  &.active {
    background: white;
    border-color: ${theme.productPage.tabs.barActiveColor};
    position: relative;
    color: ${theme.colors.font};
  }
`;

const TabsBody = styled('div')`
  width: 100%;
  padding: 2rem 0;
`;

export const TabContent = styled('article')`
  p,
  th,
  td {
    font-size: ${theme.typography.paragraph.fontSize};
    line-height: ${theme.typography.paragraph.lineHeight};
  }
  p {
    margin-bottom: ${theme.typography.paragraph.marginBottom};
  }
  iframe {
    max-width: 100%;
  }
  .custom-tab-page {
    table {
    }
  }
`;

export const DesktopTabs = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <TabsWrapper>
      <TabsHeader className={tabsData.length < 4 ? 'below-3' : ''}>
        {tabsData &&
          tabsData.map((item, index) => {
            if (item.header)
              return (
                <HeaderItem
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={index === activeTab && 'active'}
                  id={'tab-header-' + item.id}
                >
                  {item.header}
                </HeaderItem>
              );
            else return null;
          })}
      </TabsHeader>
      <TabsBody>
        <TabContent>
          <div>{tabsData[activeTab].body}</div>
        </TabContent>
      </TabsBody>
    </TabsWrapper>
  );
};
